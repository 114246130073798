<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle> Добро пожаловать! </VCardTitle>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Home',
  inject: ['Names'],
};
</script>
